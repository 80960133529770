// Import everything from autoload folder
/* global ajax */
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  /**
   * Add class on Safari
   */
  if (
    navigator.userAgent.search('Safari') >= 0 &&
    navigator.userAgent.search('Chrome') < 0
  ) {
    $('.accordion-title').addClass('no-padding');
  }
  /**
   * Add active class to header
   */
  $('.menu-icon').on('click', function () {
    $('.header').toggleClass('is-active');
    $('body').toggleClass('fixed');
    $('html').toggleClass('fixed');
  });
  /**
   * Connect accordion with home-slider
   */
  $('div[data-slide]').on('click', function () {
    // e.preventDefault();
    let slide_active = $(this).data('slide');
    $('.home-slider').slick('slickGoTo', slide_active - 1);
  });

  $('.home-slider').on(
    'beforeChange',
    function (event, slick, currentSlide, nextSlide) {
      $('div[data-slide]').each(function () {
        let link_active = $(this).data('slide');
        let slide = $('div[data-slide]').eq(nextSlide).attr('data-slick-index');
        if (link_active - 1 == slide) {
          $(this).addClass('is-active');
        } else {
          $(this).removeClass('is-active');
          $(this).find('.accordion-content').attr('style', 'display:none;');
        }
      });
    }
  );
  /**
   * Teams hover effects on click
   */
  $('.teams-section__items').click(function () {
    $('.teams-section__items').removeClass('active');
    $(this).addClass('active');
  });
  /**
   * Counter Up
   */
  (function ($) {
    $.fn.countTo = function (options) {
      options = options || {};

      return $(this).each(function () {
        // set options for current element
        let settings = $.extend(
          {},
          $.fn.countTo.defaults,
          {
            from: $(this).data('from'),
            to: $(this).data('to'),
            speed: $(this).data('speed'),
            refreshInterval: $(this).data('refresh-interval'),
            decimals: $(this).data('decimals'),
          },
          options
        );

        // how many times to update the value, and how much to increment the value on each update
        let loops = Math.ceil(settings.speed / settings.refreshInterval),
          increment = (settings.to - settings.from) / loops;

        // references & variables that will change with each update
        let self = this,
          $self = $(this),
          loopCount = 0,
          value = settings.from,
          data = $self.data('countTo') || {};

        $self.data('countTo', data);

        // if an existing interval can be found, clear it first
        if (data.interval) {
          clearInterval(data.interval);
        }
        data.interval = setInterval(updateTimer, settings.refreshInterval);

        // initialize the element with the starting value
        render(value);

        function updateTimer() {
          value += increment;
          loopCount++;

          render(value);

          if (typeof settings.onUpdate == 'function') {
            settings.onUpdate.call(self, value);
          }

          if (loopCount >= loops) {
            // remove the interval
            $self.removeData('countTo');
            clearInterval(data.interval);
            value = settings.to;

            if (typeof settings.onComplete == 'function') {
              settings.onComplete.call(self, value);
            }
          }
        }

        function render(value) {
          let formattedValue = settings.formatter.call(self, value, settings);
          $self.html(formattedValue);
        }
      });
    };

    $.fn.countTo.defaults = {
      from: 0, // the number the element should start at
      to: 0, // the number the element should end at
      speed: 1000, // how long it should take to count between the target numbers
      refreshInterval: 100, // how often the element should be updated
      decimals: 0, // the number of decimal places to show
      formatter: formatter, // handler for formatting the value before rendering
      onUpdate: null, // callback method for every time the element is updated
      onComplete: null, // callback method for when the element finishes updating
    };

    function formatter(value, settings) {
      return value.toFixed(settings.decimals);
    }
  })(jQuery);
  /**
   * Map interactive
   */
  let map = $('path');
  let info = $('.hide-info');
  let x;
  for (let i = 0; i < map.length; i++) {
    let p = map[i]['id'];
    for (let j = 0; j < info.length; j++) {
      x = info[j].getAttribute('data-id');
      // console.log(x);
      if (p == x) {
        map[i].classList.add('st1');
      }
    }
  }
  $('path').hover(function () {
    let a = $(this).attr('id');
    info.each(function () {
      if ($(this).data('id') == a) {
        info.removeClass('show');
        $(this).addClass('show');
      } else {
        $(this).removeClass('show');
      }
    });
  });
  $('path').click(function () {
    let a = $(this).attr('id');
    info.each(function () {
      if ($(this).data('id') == a) {
        info.removeClass('show');
        $(this).addClass('show');
      } else {
        $(this).removeClass('show');
      }
    });
  });
  /**
   * Make 1 word
   */
  const phrases = document.querySelectorAll('.red-label');
  for (const phrase of phrases) {
    const words = phrase.innerHTML.split(' ');
    words[0] = `${words[0]}`;
    if (words[0].length >= 5) {
      phrase.innerHTML = words[0];
    }
  }
  /**
   * Tooplip custom
   */
  $('.show').on('click', function () {
    $(this).toggleClass('active');
    $('.show').each(function () {});
    $(this).parents('.columns').find('.desc').toggleClass('hide');
  });
  /**
   * Flip on mobile
   */
  $('.commitments__item').on('click', function () {
    $(this).toggleClass('active');
  });
  /**
   * Players Slider
   */
  $('.players-slider').slick({
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    cssProps: {
      visibleY: true,
      viewportY: true,
      scrollPercentY: true,
    },
    threshold: 0.3,
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });
  /**
   * Add fancybox Single Popup
   */
  $('a.selector').on('click', function (e) {
    var $toggle = $(this),
      productID = $toggle.data('post_id');
    e.preventDefault();
    $.fancybox.open({
      src: ajax.url,
      type: 'ajax',
      opts: {
        animationDuration: 366,
        animationEffect: 'slide-in-out',
        centerOnScroll: true,
        autoFocus: false,
        ajax: {
          settings: {
            dataType: 'html',
            type: 'POST',
            data: {
              post_id: productID,
              action: 'get_post_content',
              nonce: ajax.nonce,
            },
          },
        },
      },
    });
  });

  // if ($(window).width() < 768) {
  //   $('.fancybox').fancybox({
  //     animationDuration: 0,
  //     animationEffect: 'fade',
  //   });
  // } else {
  //   $('.fancybox').fancybox({
  //     animationDuration: 800,
  //     animationEffect: 'slide-in-out',
  //   });
  // }

  /**
   * Custom Select and options
   */
  // $('select').each(function () {
  //   var $this = $(this),
  //     numberOfOptions = $(this).children('option').length;
  //
  //   $this.addClass('select-hidden');
  //   $this.wrap('<div class="select"></div>');
  //   $this.after('<div class="select-styled"></div>');
  //
  //   var $styledSelect = $this.next('div.select-styled');
  //
  //   var $list = $('<ul />', {
  //     class: 'select-options',
  //   }).insertAfter($styledSelect);
  //
  //   for (var i = 0; i < numberOfOptions; i++) {
  //     $('<li />', {
  //       text: $this.children('option').eq(i).text(),
  //       rel: $this.children('option').eq(i).val(),
  //     }).appendTo($list);
  //     if ($this.children('option').eq(i).is(':selected')) {
  //       $('li[rel="' + $this.children('option').eq(i).val() + '"]').addClass(
  //         'is-selected'
  //       );
  //     }
  //   }
  //
  //   var $listItems = $list.children('li');
  //
  //   $styledSelect.click(function (e) {
  //     e.stopPropagation();
  //     $('div.select-styled.active')
  //       .not(this)
  //       .each(function () {
  //         $(this).removeClass('active').next('ul.select-options').hide();
  //       });
  //     $(this).toggleClass('active').next('ul.select-options').toggle();
  //   });
  //
  //   $listItems.click(function (e) {
  //     e.stopPropagation();
  //     $styledSelect.text($(this).text()).removeClass('active');
  //     $this.val($(this).attr('rel'));
  //     $list.hide();
  //   });
  //
  //   $(document).click(function () {
  //     $styledSelect.removeClass('active');
  //     $list.hide();
  //   });
  // });
  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      // setTimeout(() => window.location.reload(), 10000);
      return false;
    }
  });
  /**
   * Scroll to Gravity Form confirmation message after form submit
   * and reload page on Tryouts Page
   */
  if ($('body').hasClass('page-template-template-tryouts')) {
    $(document).on('gform_confirmation_loaded', function (event, formId) {
      let $target = $('#gform_confirmation_wrapper_' + formId);
      if ($target.length) {
        setTimeout(() => window.location.reload(), 10000);
        return false;
      }
    });
  }

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );
  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */

  $('.title-bar').on('toggled.zf.responsiveToggle', function () {
    $('.menu-icon').toggleClass('active');
  });
  $(window).on('changed.zf.mediaquery', function () {
    $('.menu-icon').removeClass('active');
  });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('active') && window.innerWidth < 3000) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });
  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  let a = $('.table__container').attr('data-scroll');
  if (a == 'in' && $(document).scrollTop() >= $(document).height() / 100) {
    jQuery(function ($) {
      // custom formatting example
      $('.number-counterup').data('countToOptions', {
        formatter: function (value, options) {
          return value
            .toFixed(options.decimals)
            .replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
        },
      });

      // start all the timers
      $('.number-counterup').each(count);

      function count(options) {
        var $this = $(this);
        options = $.extend(
          {},
          options || {},
          $this.data('countToOptions') || {}
        );
        $this.countTo(options);
        return;
      }
    });
    $(window).off('scroll');
  }
});
